:root {
  /* No reference found for  to --color-empty-label and --color-table-header-bg - delete them? */
  --color-empty-label: var(--color-primary);
  --color-table-header-bg: var(--color-primary);
  --background-color-quote-dropdown: var(--color-primary);
  --color-quote-dropdown: #ffffff;
  --border-quote-dropdown: none;
  --border-radius-quote-dropdown: 0;
  --color-table-header-border: var(--color-grey-8-darker);
  --color-quote-total-price: var(--color-primary);
  --heigh-quote-total-price: auto;
  --font-size-quote-total-label: var(--font-size-small);
  --padding-top-quote-dropdown: 10px;
  --border-top-quote-summary-accordion: 1px solid var(--color-grey-3);
  --margin-top-quote-summary-accordion: 10px;
  --padding-top-quote-summary-accordion: 10px;
}
