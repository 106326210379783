:root {
  --reset-button-border: 1px solid var(--color-primary-60);
  --reset-button-border-radius: 16px;
  --reset-button-text-color: var(--color-grey-8);
  --reset-button-font-size: var(--font-size-smallxs);
  --button-mobile-background-color: var(--color-grey-purewhite);
  --button-mobile-border-radius: 25px;
  --button-mobile-padding: 12px 15px;
  --button-mobile-text-color: var(--color-primary);
  --button-mobile-font-size: var(--font-size-small);
  --button-mobile-font-weight: var(--font-weight-semibold);

  --filter-popup-button-border: 1px solid var(--color-primary);
  --filter-popup-button-border-radius: 25px;
  --filter-popup-button-font-size: var(--font-size-small);
  --filter-popup-button-font-weight: var(--font-weight-bold);
  --filter-popup-reset-button-background: var(--color-grey-purewhite);
  --filter-popup-reset-button-text-color: var(--color-primary);
  --filter-popup-apply-button-background: var(--color-primary);
  --filter-popup-apply-button-text-color: var(--color-grey-purewhite);
}
