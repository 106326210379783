:root {
  --square-card-bg-gradient: linear-gradient(0deg, #00000000 0%, #00000000 47%, #00000077 100%) 0% 0% no-repeat;
  --square-card-border-radius: 15px;
  --square-card-padding: 20px;
  --square-card-justify-content: flex-end;
  --square-card-display-details: block;
  --square-card-display-largethums-content: none;
  --square-card-display-overlay: none;
  --square-card-title-font-size: 18px;
  --square-card-title-font-size-md: 20px;
  --square-card-title-font-size-lg: 24px;
  --square-card-hover-transform: none;
  --square-card-hover-box-shadow: none;
  --square-card-hover-opacity: 0;
}
