:root {
  --padding-quote-subtotal: unset;
  --padding-quote-subtotal-sm: unset;
  --overflow-quote-subtotal: hidden;
  --overflow-quote-subtotal-sm: unset;
  --border-top-quote-subtotal: unset;
  --font-size-quote-subtotal-label: 13px;
  --font-size-quote-subtotal-label-sm: 13px;
  --color-quote-subtotal-label: #202020;
  --color-quote-subtotal-price: var(--color-primary);
  --padding-top-quote-subtotal-label: 15px;
}
