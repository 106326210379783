/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 5vh 5vw;
}

@media print {
  @page {
    size: A4;
  }
  body {
    width: 100%;
    margin: 0;
    display: block;
    overflow: visible;

    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .table-section {
    page-break-inside: avoid;
  }
}
