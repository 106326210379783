:root {
  --color-age-group-students: var(--color-primary);
  --color-age-group-juniors: var(--color-primary);

  --color-age-group-students-darken-x1: #004691;
  --color-age-group-students-darken-x2: #004289;
  --color-age-group-students-darken-x3: #003e82;

  --color-age-group-juniors-darken-x1: #004691;
  --color-age-group-juniors-darken-x2: #004289;
  --color-age-group-juniors-darken-x3: #003e82;

  --klg-quote-tool-primary-color: var(--color-primary);
  --klg-quote-tool-nav-color: var(--color-primary);
  --klg-quote-tool-alert-color: var(--color-primary);
  --klg-quote-tool-secondary-color-adults: var(--color-secondary-adults);
  --klg-quote-tool-secondary-color-juniors: var(--color-secondary-juniors);
  --klg-quote-tool-secondary-color-online: var(--color-secondary-online);

  --klg-quote-tool-primary-color-darken-x1: #013f81;
  --klg-quote-tool-primary-color-darken-x2: #013267;
  --klg-quote-tool-primary-color-darken-x3: #002246;

  --klg-quote-tool-secondary-color-adults-darken-x1: #d2a501;
  --klg-quote-tool-secondary-color-adults-darken-x2: #b68f02;
  --klg-quote-tool-secondary-color-adults-darken-x3: #8d6f02;

  --klg-quote-tool-secondary-color-juniors-darken-x1: #d96e6f;
  --klg-quote-tool-secondary-color-juniors-darken-x2: #af5859;
  --klg-quote-tool-secondary-color-juniors-darken-x3: #814141;
}
