:root {
  --button-group-item-margin-right: 10px;
  --button-group-item-margin-left: 10px;
  --button-group-item-border: 1px solid var(--color-grey-4);
  --button-group-item-content-background: var(--color-grey-purewhite);
  --button-group-item-content-padding: 8px 12px;
  --button-group-item-text-color: var(--color-grey-pureblack);
  --button-group-item-font-size: var(--font-size-normal);
  --button-group-item-font-weight: var(--font-weight-regular);
  --button-group-item-background-mousehover: var(--color-grey-purewhite);
  --button-group-item-border-mousehover: 1px solid var(--color-primary);
  --button-group-item-text-color-mousehover: var(--color-primary);
  --button-group-item-box-shadow-mousehover: none;
  --button-group-selected-item-border: 1px solid var(--color-primary);
  --button-group-selected-item-background: var(--color-grey-purewhite);
  --button-group-selected-item-text-color: var(--color-primary);
  --button-group-selected-item-font-size: var(--font-size-normal);
  --button-group-selected-item-font-weight: var(--font-weight-semibold);
  --button-group-selected-item-icon-color: var(--color-primary);
  --button-group-selected-item-background-mousehover: var(--color-grey-purewhite);
}
