:root {
  --color-calendar-primary: var(--color-primary);
  --color-calendar-sm-bg: var(--color-grey-purewhite);
  --color-calendar-month: var(--color-primary);
  --color-calendar-day: var(--color-primary);
  --color-calendar-day-bg: var(--color-primary-20);
  --color-calendar-day-active: var(--color-grey-purewhite);
  --color-calendar-day-active-bg: var(--color-primary);
  --color-calendar-period: var(--color-primary-20);
  --color-calendar-today: var(--color-grey-9);
  --color-calendar-today-bg: var(--color-secondary-1, 0.2);
}
