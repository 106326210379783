:root {
  --icon-spacing-xs: 6px;
  --icon-spacing-s: 8px;
  --icon-spacing-m: 18px;
  --school-image-width: 138px;
  --school-image-compacted-width: 128px;
  --school-image-height-xs: 128px;
  --school-image-height-s: 178px;
  --school-image-height-m: 210px;
  --school-image-height-l: 226px;
  --adult-icon-color: var(--color-primary);
  --junior-icon-color: var(--color-secondary-2);
}
