:root {
  /* colors */
  --color-primary: #0046b4;
  --color-primary-80: #336ead;
  --color-primary-60: #6491d2;
  --color-primary-40: #99b7d6;
  --color-primary-20: #ccdbeb;

  --color-primary-rgb: 0, 70, 180;

  --color-secondary-1: #fac800;
  --color-secondary-1-deep: #e4a200;
  --color-secondary-2: #ff8385;
  --color-secondary-2-deep: #e45c5e;
  --color-secondary-3: #4089e4;
  --color-secondary-3-deep: #2662c2;

  --color-secondary-eslgreen: #59cb59;
  --color-secondary-deepeslgreen: #39a539;
  --color-secondary-eslred: #c63434;
  --color-secondary-deepeslred: #a01e1e;
  --color-secondary-eslorange: #fc8943;
  --color-secondary-deepeslorange: #e06228;

  /* New colour names for Kaplan although the ones above may still be used */
  --color-secondary-adults: var(--color-secondary-1);
  --color-secondary-juniors: var(--color-secondary-2);
  --color-secondary-online: #240f6e;

  --color-button-popup-remove: #ec0928;
  --color-button-popup-remove-5: #cb0a25;
  --color-button-popup-remove-10: #a80b1f;
  --color-button-popup-remove-15: #850b1a;

  --color-button-adults: #fac800ff;
  --color-button-adults-5: #deb202;
  --color-button-adults-10: #bd9701;
  --color-button-adults-15: #9a7b01;

  --color-accent-red: #d6083b;
  --color-accent-deepred: #9e1539;
  --color-accent-blue: #005de8;
  --color-accent-deepblue: #082ecf;
  --color-accent-green: #4e9d2d;
  --color-accent-deepgreen: #35771f;
  --color-accent-chatreuse: #c1d82f;
  --color-accent-deepchatreuse: #8ba616;
  --color-accent-yellow: #ffc82e;
  --color-accent-deepyellow: #c18a24;
  --color-accent-orange: #e55302;
  --color-accent-deeporange: #b6340d;
  --color-accent-pink: #e62899;
  --color-accent-deeppink: #ab0c78;

  --color-neutral-warmgrey: #d7d2cb;
  --color-neutral-deepwarmgrey: #837870;
  --color-neutral-coolgrey: #bbbab9;
  --color-neutral-deepcoolgrey: #747679;
  --color-neutral-wheat: #ebeb74;
  --color-neutral-stone: #d4c69e;
  --color-neutral-offwhite: #f5f3e7;

  --color-text-light: #808080;
  --color-text-normal: #606060;
  --color-text-dark: #404040;
  --color-text-darker: #202020;
  --color-text-mousehover: var(--button-primary-background-x4);
  --color-text-highlight: #240f6e;
  --color-error: var(--color-accent-red);
}
