/* Style resets to resolve overwrites from PrimeNG themes at  */
a,
body a {
  color: #007ad9;
  text-decoration: none;
}
a:hover,
body a:hover {
  color: #116fbf;
  text-decoration: none;
}
.btn-primary:hover,
.paragraph-homepage-country-list__field-link a:hover {
  color: #ffffff;
}
