:root {
  /* Primary Colours */
  --button-primary-background: var(--color-primary);
  --button-primary-background-x1: #004894;
  --button-primary-background-x3: #00438a;
  --button-primary-background-x4: #004085;
  --button-primary-background-x10: #003166;
  --button-primary-background-x15: #00254d;
  --button-primary-text-color: white;
  --button-primary-border-color: var(--color-primary);

  --button-primary-background-disabled: var(--color-grey-4);
  --button-primary-text-color-disabled: var(--color-grey-purewhite);
  --button-primary-border-disabled: var(--color-grey-4);
  --button-primary-opacity-disabled: 0.5;

  --button-primary-background-hover: var(--color-text-mousehover);
  --button-primary-text-color-hover: white;
  --button-primary-border-color-hover: var(--color-text-mousehover);

  --button-primary-background-focus: var(--color-primary);
  --button-primary-text-color-focus: white;
  --button-primary-border-color-focus: var(--color-primary);

  --button-primary-background-active: var(--color-primary);
  --button-primary-text-color-active: white;
  --button-primary-border-color-active: var(--color-primary);

  --button-primary-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  --button-primary-box-shadow-focus: var(--button-primary-box-shadow);
  --button-primary-box-shadow-active: none;

  /* Secondary Colours */
  --button-secondary-background: transparent;
  --button-secondary-text-color: var(--color-primary);
  --button-secondary-border-color: var(--color-primary);

  --button-secondary-background-disabled: white;
  --button-secondary-text-color-disabled: var(--color-grey-4);
  --button-secondary-border-disabled: var(--color-grey-4);

  --button-secondary-background-hover: transparent;
  --button-secondary-text-color-hover: var(--color-text-mousehover);
  --button-secondary-border-color-hover: var(--color-text-mousehover);

  --button-secondary-background-focus: transparent;
  --button-secondary-text-color-focus: var(--color-primary);
  --button-secondary-border-color-focus: var(--color-primary);

  --button-secondary-background-active: transparent;
  --button-secondary-text-color-active: var(--color-text-mousehover);
  --button-secondary-border-color-active: var(--color-text-mousehover);

  --button-secondary-box-shadow: var(--button-primary-box-shadow);
  --button-secondary-box-shadow-active: var(--button-primary-box-shadow-active);

  /* Tertiary Colours */
  --button-tertiary-background: white;
  --button-tertiary-text-color: var(--color-primary);
  --button-tertiary-border-color: white;

  --button-tertiary-background-disabled: var(--color-grey-4);
  --button-tertiary-text-color-disabled: white;
  --button-tertiary-border-color-disabled: var(--color-grey-4);

  --button-tertiary-background-hover: var(--color-grey-2);
  --button-tertiary-text-color-hover: var(--color-text-mousehover);
  --button-tertiary-border-color-hover: var(--color-grey-2);

  --button-tertiary-background-focus: white;
  --button-tertiary-text-color-focus: var(--color-primary);
  --button-tertiary-border-color-focus: white;

  --button-tertiary-background-active: var(--color-grey-2);
  --button-tertiary-text-color-active: var(--color-primary);
  --button-tertiary-border-color-active: var(--color-grey-2);

  /* Quaternary Colours */
  --button-quaternary-background: white;
  --button-quaternary-text-color: var(--color-primary);
  --button-quaternary-border-color: var(--color-primary);

  --button-quaternary-background-disabled: var(--color-grey-4);
  --button-quaternary-text-color-disabled: white;
  --button-quaternary-border-color-disabled: var(--color-grey-4);

  --button-quaternary-background-hover: var(--color-grey-1-darker);
  --button-quaternary-text-color-hover: var(--color-primary);
  --button-quaternary-border-color-hover: var(--color-primary);

  --button-quaternary-background-focus: var(--color-grey-1-darker);
  --button-quaternary-text-color-focus: var(--color-primary);
  --button-quaternary-border-color-focus: var(--color-primary);

  --button-quaternary-background-active: var(--color-grey-1-darker);
  --button-quaternary-text-color-active: var(--color-primary);
  --button-quaternary-border-color-active: var(--color-primary);

  /* Adults Colours */
  --button-adults-background: var(--color-button-adults);
  --button-adults-text-color: var(--color-primary);
  --button-adults-border-color: var(--color-button-adults);

  --button-adults-background-disabled: var(--color-button-adults);
  --button-adults-text-color-disabled: var(--color-primary);
  --button-adults-border-color-disabled: var(--color-button-adults);

  --button-adults-background-hover: var(--color-button-adults-5);
  --button-adults-text-color-hover: var(--color-primary);
  --button-adults-border-color-hover: var(--color-button-adults-5);

  --button-adults-background-focus: var(--color-button-adults);
  --button-adults-text-color-focus: var(--color-primary);
  --button-adults-border-color-focus: var(--color-button-adults);

  --button-adults-background-active: var(--color-button-adults-15);
  --button-adults-text-color-active: var(--color-primary);
  --button-adults-border-color-active: var(--color-button-adults-15);

  /* Juniors Colours */
  --button-juniors-background: var(--klg-quote-tool-secondary-color-juniors);
  --button-juniors-text-color: white;
  --button-juniors-border-color: var(--color-grey-4);

  --button-juniors-background-disabled: var(--color-grey-3);
  --button-juniors-text-color-disabled: white;
  --button-juniors-border-color-disabled: var(--color-grey-3);

  --button-juniors-background-hover: var(--klg-quote-tool-secondary-color-juniors-darken-x1);
  --button-juniors-text-color-hover: white;
  --button-juniors-border-color-hover: var(--klg-quote-tool-secondary-color-juniors-darken-x1);

  --button-juniors-background-focus: var(--klg-quote-tool-secondary-color-juniors-darken-x2);
  --button-juniors-text-color-focus: white;
  --button-juniors-border-color-focus: var(--klg-quote-tool-secondary-color-juniors-darken-x2);

  --button-juniors-background-active: var(--klg-quote-tool-secondary-color-juniors-darken-x3);
  --button-juniors-text-color-active: white;
  --button-juniors-border-color-active: var(--klg-quote-tool-secondary-color-juniors-darken-x3);

  /* Popup Remove Colours */
  --button-popup-remove-background: var(--color-button-popup-remove);
  --button-popup-remove-text-color: white;
  --button-popup-remove-border-color: var(--color-button-popup-remove);

  --button-popup-remove-background-disabled: var(--color-grey-3);
  --button-popup-remove-text-color-disabled: white;
  --button-popup-remove-border-color-disabled: var(--color-grey-3);

  --button-popup-remove-background-hover: var(--color-button-popup-remove-5);
  --button-popup-remove-text-color-hover: white;
  --button-popup-remove-border-color-hover: var(--color-button-popup-remove-5);

  --button-popup-remove-background-focus: var(--color-button-popup-remove-10);
  --button-popup-remove-text-color-focus: white;
  --button-popup-remove-border-color-focus: var(--color-button-popup-remove-10);

  --button-popup-remove-background-active: var(--color-button-popup-remove-15);
  --button-popup-remove-text-color-active: white;
  --button-popup-remove-border-color-active: var(--color-button-popup-remove-15);

  /* Link Button Colours */
  --button-link-text-color-disabled: var(--color-grey-4);

  /* Button Icon Colours */
  --button-filled-icon-text: white;

  /* Border colours for some selectors */
  --button-outline-color: var(--color-primary);
  --button-outline-color-light: var(--color-primary-20);

  /* More Button Colours */
  --button-more-background: var(--color-grey-1-darker);
  --button-more-outline-color-active: #f9f9f9;
  --button-more-outline-color: var(--color-grey-1-darker);
  --button-more-background-active: var(--color-grey-purewhite);

  /* Help Button Colours */
  --button-help-background: var(--color-grey-purewhite);
  --button-help-background-active: var(--color-grey-purewhite);
  --button-help-border-color: var(--color-grey-purewhite);
  --button-help-border-color-active: #7fa4cc;

  /* Font Sizes */
  --button-font-size-xs: 12px;
  --button-font-size-s: 13px;
  --button-font-size-m: var(--font-size-normal);
  --button-font-size-l: 16px;
  --button-font-size-xl: 18px;

  /* Line Heights */
  --button-line-height-xs: 14px;
  --button-line-height-s: 16px;
  --button-line-height-m: 16px;
  --button-line-height-l: 18px;
  --button-line-height-xl: 21px;

  /* Font Weights */
  --button-font-weight: var(--font-weight-bold);

  /* Border Radius */
  --button-border-width: 2px;
  --button-border-radius: 4px;
  --button-border-radius-xs: 2px;
  --button-border-radius-s: 3px;
  --button-border-radius-m: var(--button-border-radius);
  --button-border-radius-l: 5px;
  --button-border-radius-xl: 6px;

  /* Padding */
  --button-padding-xs: 7px 14px;
  --button-padding-s: 10px 20px;
  --button-padding-m: 14px 28px;
  --button-padding-l: 16px 32px;
  --button-padding-xl: 18px 36px;

  /* Border Widths */
  --button-border-width-xs: var(--button-border-width-s);
  --button-border-width-s: 1px;
  --button-border-width-m: 2px;
  --button-border-width-l: var(--button-border-width-m);
  --button-border-width-xl: var(--button-border-width-m);

  --button-outline-color-active: #336dae;
}
